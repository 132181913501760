/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
// import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
// import DashboardPage from "views/Dashboard/Dashboard.js";
// import UserProfile from "views/UserProfile/UserProfile.js";
// import TableList from "views/TableList/TableList.js";
// import Typography from "views/Typography/Typography.js";
// import Icons from "views/Icons/Icons.js";
// import Maps from "views/Maps/Maps.js";
// import NotificationsPage from "views/Notifications/Notifications.js";
// import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
import Blacklist from "views/Blacklist/Blacklist.js";
import HistoryReport from "views/HistoryReport/HistoryReport.js";
// core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.js";
import Posts from "views/Posts/Posts.js"
import UsersPage from "views/Users/Users.js"
import Logs from "views/Logs/Logs"
import {getUser} from "Utils/Common"
import PeopleIcon from '@material-ui/icons/People';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import PostAddIcon from '@material-ui/icons/PostAdd';
import HistoryIcon from '@material-ui/icons/History';




const adminRoutes = [
  {
    path: "/users",
    name: "สมาชิกทั้งหมด",
    rtlName: "สมาชิกทั้งหมด",
    icon: PeopleIcon,
    component: UsersPage,
    layout: "/m"
  },
  {
    path: "/logs",
    name: "logs",
    rtlName: "logs",
    icon: HistoryIcon,
    component: Logs,
    layout: "/m"
  },


]

const dashboardRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   rtlName: "لوحة القيادة",
  //   icon: Dashboard,
  //   component: DashboardPage,
  //   layout: "/m"
  // },
  {
    path: "/blacklist",
    name: "รปภ.รหัสแดง",
    rtlName: "รปภ.รหัสแดง",
    icon: Person,
    component: Blacklist,
    layout: "/m"
  },
  {
    path: "/history_report",
    name: "ประวัติการรายงาน",
    rtlName: "ประวัติการรายงาน",
    icon: RestorePageIcon,
    component: HistoryReport,
    layout: "/m"
  },
  // {
  //   path: "#",
  //   name: "รปภ.ดีเด่น",
  //   rtlName: "รปภ.ดีเด่น",
  //   icon: "content_paste",
  //   component: TableList,
  //   layout: "/m",
  // },
  {
    path: "/posts",
    name: "กระทู้ถามตอบ",
    rtlName: "กระทู้ถามตอบ",
    icon: PostAddIcon,
    component: Posts,
    layout: "/m"
  },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/m"
  // },
  // {
  //   path: "/user",
  //   name: "user",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: UserProfile,
  //   layout: "/m"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: "/m"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/m"
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL Support",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Language,
  //   component: RTLPage,
  //   layout: "/rtl"
  // },
  // {
  //   path: "/upgrade-to-pro",
  //   name: "Upgrade To PRO",
  //   rtlName: "التطور للاحترافية",
  //   icon: Unarchive,
  //   component: UpgradeToPro,
  //   layout: "/m"
  // }
];

try {
  if (getUser().level > 1) {
    adminRoutes.map((val) => {
      dashboardRoutes.push(val)
    })
  }

} catch (err) {
  console.log(err)
}

export default dashboardRoutes;
