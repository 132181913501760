import React from "react"
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Grid from '@material-ui/core/Grid';
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CONFIG from "../../config.json"
import axios from "axios"
import { getToken } from "Utils/Common";
import { getUser } from "Utils/Common";
import Button from "@material-ui/core/Button"
import { TextField } from "@material-ui/core"
import { removeUserSession } from "Utils/Common"
// import Rating from '@material-ui/lab/Rating';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { CameraAltSharp as CamIcon } from "@material-ui/icons"
// import Rating from '@material-ui/lab/Rating';
import Rating from 'material-ui-rating'
import StarIcon from "@material-ui/icons/Star"


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);
export default function CompanyDetail(props) {
    // console.log(props)
    // {props.match.params.ref_id}
  const vote_api = (ref, score) => {
    let url_api = CONFIG.backend.main_url + "/user_api/out_com/"+ref+"/vote" 
    // const con = window.confirm("คุณต้องการลบโพสนี้จริงหรือไม่?")
    // if (!con) {
    //   return false
    // }
    axios.post(url_api, {
      score: score
    },{
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        alert("อัพเดตการโหวตแล้ว")
        window.location.reload()
        
      }
    }).catch(async (err) => {
      if (err.response.data == "error token") {
        await removeUserSession()
        window.location.reload()
      }
    })
  }
  const send_comment = (post_id) => {
    const comment = document.getElementById("msg_commentcom").value
    const fd = new FormData()
    fd.append("comment", comment);
    if (img_comment.length > 0) {
      fd.append("img", img_comment[0])

    }
    axios.post(CONFIG.backend.main_url + "/user_api/out_com/"+post_id+"/comment", fd,{
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        // alert("แสดงความคิดเห็นแล้ว")
        document.getElementById("img_comment").value = ""
        setImg_comment([])
        document.getElementById("msg_commentcom").value = ""
        // window.location.reload()
        set_posts()
        
      }
    }).catch(async (err) => {
      if (err.response.data == "error token") {
        await removeUserSession()
        window.location.reload()
      }
    })

  }
  const convertTime = (myTime) => {
    let m = new Date(myTime)
    let dateString = m.getUTCFullYear() +"/"+ (m.getUTCMonth()+1) +"/"+ m.getUTCDate() + " " + m.getUTCHours() + ":" + m.getUTCMinutes() ;
    return dateString

  }
  const del_comment = (post_id, comment_id) => {
    let url_api = CONFIG.backend.main_url + "/user_api/out_com/"+post_id+"/comment/"+comment_id+"/del" 
    if (getUser().level > 1) {
      url_api = CONFIG.backend.main_url + "/admin/out_com/"+post_id+"/comment/"+comment_id+"/del" 
    }
    const con = window.confirm("คุณต้องการลบโพสนี้จริงหรือไม่?")
    if (!con) {
      return false
    }
    axios.post(url_api , {
    },{
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        // alert("ลบความคิดเห็นแล้ว")
        alert("อัพเดตการโหวตแล้ว")
        // window.location.reload()
        set_posts()
        
      }
    }).catch(async (err) => {
      if (err.response.data == "error token") {
        await removeUserSession()
        window.location.reload()
      }
    })
  }
  
    const [infocom, setInfo] = React.useState({comments: [], imgs: [], vote: []})
    const [img_comment, setImg_comment] = React.useState({})
    const this_userVote = () =>{
      let myVote = 0
      
      for (let i = 0; i < infocom.vote.length; i++){
        if (infocom.vote[i].from_user == getUser().username) {


          myVote = infocom.vote[i].score
        }
      }
      return myVote

    }
    const set_posts = () => {
        const ref_id = props.match.params.ref_id
        axios.get(`${CONFIG.backend.main_url}/user_api/out_com/${ref_id}`, {
            headers: {
                "auth-token": getToken()
            }
        }).then((result) => {
            setInfo(result.data)

        }).catch(err => {
            console.log(err)
            window.location.href = "/"

        })

    }
    React.useEffect(() => {
        set_posts()

    }, [])
    React.useEffect(() => {
        console.log(infocom)
    }, [infocom])
    const classes = useStyles();

    return <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>รายละเอียด บริษัทดีเด่น</h4>
            {/* <p className={classes.cardCategoryWhite}>
              ตารางบริษัทดีเด่น
            </p> */}
          </CardHeader>
          <CardBody>
            <Grid container spacing={3}>
            <Grid item xs={6}>
                <div>
                                <img src={`${CONFIG.backend.main_url}/profile_out_com/${infocom.img}`} width="100px"  />
                                <h5>ชื่อบริษัท: {infocom.name}
                                <br />
                                หัวข้อที่ได้รับรางวัล: {infocom.title_reward }<br />
                                รายละเอียด: {infocom.detail}<br />
                                วันที่ได้รับรางวัล: {convertTime(infocom.timestamp)}</h5>
                </div>
                {
                  console.log(infocom)
                }
                <div style={{ display: "flex" }}>
                <Rating size="small" name="this_rating" precision={0.5} value={infocom.score} readOnly />
                <small style={{color: "gray"}}>{
                  "\t "+Number(infocom.score).toFixed(1)
                } ดาว / {infocom.vote.length} โหวต</small>

</div>
                
        <div><span>คุณโหวต: <select value={this_userVote()} onChange={(e) => { vote_api(infocom.ref, e.target.value)}}><option value={0} disabled>ยังไม่โหวต</option><option value={1}>1 ดาว</option><option value={2}>2 ดาว</option><option value={3}>3 ดาว</option><option value={4}>4 ดาว</option><option value={5}>5 ดาว</option></select></span></div>

            </Grid>

            <Grid item xs={6}>
                <h5>รูปภาพ</h5>
                            {
                                infocom.imgs.length == 0 ? "ไม่มีรูป" : 
                                    infocom.imgs.map((val) => {
                                        return (<img src={`${CONFIG.backend.main_url}/profile_out_com/${val.img}`} width="120px" />)
                                    })
                }

            </Grid>
                        <Grid item xs={12}>
                            
            {/* <List className={classes.comment}> */}
              {
                infocom.comments.map((val1, comI) => {
                  return <Card className={classes.comment}>
              <CardContent>
                  <small style={{color: "gray"}}>โดย: {getUser().username === val1.from_user? "คุณ": val1.from_user}</small><br />
                      <small style={{ color: "gray" }}>
                        <b>เวลา: </b>{convertTime(val1.timestamp)}
                      </small>
                      <div><span style={{ color: "gray" }}>ความคิดเห็น: </span>{val1.comment}<br />{val1.img.length !== 0 ? <img width="150" src={`${CONFIG.backend.main_url}/comment/${val1.img}`} onClick={() => {
                        window.open(`${CONFIG.backend.main_url}/comment/${val1.img}`)
                      }}></img> : null } </div>
                      <br />
              </CardContent>
                    {
                        getUser().username === val1.from_user || getUser().level > 1 ? <div><IconButton aria-label="delete" className={classes.margin} onClick={() => {
                          del_comment(infocom.ref, val1.id)
                      }}>
          <DeleteIcon fontSize="small" />
        </IconButton></div> : null
                    }
                    <small style={{ color: "gray", marginLeft: "auto" }}>ความคิดเห็นที่ {comI + 1}</small>
              </Card>
                })
        }
        {/* </List> */}
                {
                img_comment.length > 0 ? <div style={{width: 80, margin: "auto"}}>
                  <img width="100%" src={URL.createObjectURL(img_comment[0])} />
                  <button onClick={()=>{
                    document.getElementById("img_comment").value = null
                    setImg_comment([])
                  }}>X</button>
                </div> : null
                  
                }

<div style={{display:"flex"}}>
                <input type="file" id="img_comment" accept="image/*" onChange={(e) => {
                  setImg_comment(e.target.files)
                }} hidden></input>
                <IconButton onClick={() => {
                  document.getElementById("img_comment").click()
                }} >
                  <CamIcon />
                </IconButton>
                <TextField id={"msg_commentcom"} label="ความคิดเห็น" fullWidth /><Button color="primary" variant="outlined" onClick={() => {
                  send_comment(infocom.ref)
                }}>ส่ง</Button>
</div>
            </Grid>

              </Grid>
              

          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
}