import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo.png";
import BlacklistTable from "../views/Blacklist/BlacklistTable"
import BlacklistAdd from "../views/Blacklist/BlacklistAdd"
import SearchBlack from "../views/Blacklist/Search"
import OutGuard from "../views/Outstanding/Guard"
import OutCom from "../views/Outstanding/Company"
import OutComAdd from "../views/Outstanding/CompanyAdd"
import OutGuardAdd from "../views/Outstanding/GuardAdd"
import OutComDetail from "../views/Outstanding/CompanyDetail"
import OutGuardDetail from "../views/Outstanding/GuardDetail"
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from "@material-ui/core"
import axios from "axios"
import CONFIG from "../config.json"
import { getToken } from "Utils/Common";

let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/m") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Route path={"/m/out_guard/:ref_id"} component={OutGuardDetail} />
    <Route path={"/m/out_guard"} component={OutGuard} />
    <Route path={"/m/out_com/:ref_id"} component={OutComDetail} />
    <Route path={"/m/out_com"} component={OutCom} />
    <Route path={"/m/out_com_add"} component={OutComAdd} />
    <Route path={"/m/out_guard_add"} component={OutGuardAdd} />
    <Route path={"/m/blacklist_show"} component={BlacklistTable} />
    <Route path={"/m/search"} component={SearchBlack} />
    <Route path={"/m/blacklist_add"} component={BlacklistAdd} />
    <Redirect from="/m" to="/m/blacklist" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [openDiaPass, setDiaPass] = React.useState(false);
  const [profile, setProfile] = React.useState({})

  const get_profile = () => {
    axios.get(`${CONFIG.backend.main_url}/user_api/profile`, {
      headers: {
      "auth-token": getToken()
      }
    }).then((res) => {
      setProfile(res.data)
    })
    
  }

  const edit_profile = () => {
    const con = window.confirm("คุณต้องการแก้ไขโปรไฟล์จริงหรือไม่?")
    if (!con) {
      return false
    }
    const f_name = document.getElementById("f_name").value
    const l_name = document.getElementById("l_name").value
    axios.post(`${CONFIG.backend.main_url}/user_api/profile`, {
      f_name: f_name,
      l_name: l_name
    },{
      headers: {
      "auth-token": getToken()
      }

    }).then((res) => {
      if (res.data == "success") {
        window.alert("บันทึกสำเร็จ")
        get_profile()
      }
    }).catch((err) => {
      console.log(err)
      window.alert("เกิดข้อผิดพลาด")
    })

  }

  const ch_pass = () => {
    const old_pass = document.getElementById("old_pass").value
    const new_pass = document.getElementById("new_pass").value
    const re_pass = document.getElementById("re_pass").value
    if (new_pass !== re_pass) {
      window.alert("รหัสผ่านใหม่ต้องตรงกันทั้งสองตัว!!")
      return false
    }
    axios.post(`${CONFIG.backend.main_url}/user_api/ch_pass`, {
      old_pass: old_pass,
      new_pass: new_pass
    },{
      headers: {
      "auth-token": getToken()
      }

    }).then((res) => {
      if (res.data == "success") {
        window.alert("เปลี่ยนรหัสผ่านสำเร็จ")
        setDiaPass(false)
      }
    }).catch((err) => {
      if (err.response) {
        if (err.response.data == "password wrong") {
          window.alert("รหัสผ่านเดิมไม่ถูกต้อง")
        } else {
          window.alert("เกิดข้อผิดพลาด")
        }

      }
    })
    
  }

  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  React.useEffect(() => {
    get_profile()

  }, [])
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"ระบบ รปภ."}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        openProfile={setOpenProfile}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          openProfile={setOpenProfile}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {/* {getRoute() ? <Footer /> : null} */}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
              <Dialog open={openProfile} onClose={() => { setOpenProfile(false) }}>
                <DialogTitle>ตั้งค่าโปรไฟล์</DialogTitle>
                <DialogContent>
            <div>ชื่อผู้ใช้: <span style={{color: "gray"}}>{profile.username}</span></div>
            <TextField label="ชื่อ" defaultValue={profile.f_name} id="f_name" fullWidth  />
            <TextField label="นามสกุล" defaultValue={profile.l_name} id="l_name" fullWidth  />

                <Button color="warning" variant="contained" onClick={()=>{setDiaPass(true)}}>เปลี่ยนรหัสผ่าน</Button>
                </DialogContent>
              <DialogActions>
                <Button color="primary" variant="contained" onClick={()=>{edit_profile()}}>บันทึก</Button>

                </DialogActions>
              </Dialog>
        <Dialog open={openDiaPass}>
          <DialogTitle>เปลี่ยนรหัสผ่าน
          </DialogTitle>

          <DialogContent>
            <TextField label="รหัสผ่านเดิม" id="old_pass" type="password" fullWidth />
            <TextField label="รหัสผ่านใหม่" id="new_pass" type="password" fullWidth />
            <TextField label="รหัสผ่านใหม่อีกครั้ง" id="re_pass" type="password" fullWidth />
          </DialogContent>

          <DialogActions>
            <Button color="primary" variant="contained" onClick={()=>{ch_pass()}}>ยืนยัน</Button>
            <Button color="secondary" variant="contained" onClick={()=>{setDiaPass(false)}}>ยกเลิก</Button>
          </DialogActions>

              </Dialog>
      </div>
    </div>
  );
}
