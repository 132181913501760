import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import CONFIG from "../../config.json"
import { getToken } from "Utils/Common";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const classes = useStyles();
  const [dataTable, setTable] = React.useState([])
  const set_stateTable = () => {
    axios.get(`${CONFIG.backend.main_url}/admin/logs`, {
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      const outData = res.data.map((val) => {
        const tmp = []
        tmp.push(val.ref)
        tmp.push(val.username)
        tmp.push(val.group)
        tmp.push(val.event)
        tmp.push(val.timestamp)
        return tmp
      })
      setTable(outData)
      
    }).catch((err) => {
      console.log(err)
    })
  }
  React.useEffect(() => {
    set_stateTable()
    
  }, [])
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>logs ทั้งหมด</h4>
            <p className={classes.cardCategoryWhite}>
              ตาราง logs Event ต่างๆ
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["id",  "ผู้กระทำ", "ประเภท","ข้อความ", "วันเวลา"]}
              tableData={dataTable}
              // tableData={[
              //   ["Dakota Rice", "Niger", "Oud-Turnhout", "$36,738"],
              //   ["Minerva Hooper", "Curaçao", "Sinaai-Waas", "$23,789"],
              //   ["Sage Rodriguez", "Netherlands", "Baileux", "$56,142"],
              //   ["Philip Chaney", "Korea, South", "Overland Park", "$38,735"],
              //   ["Doris Greene", "Malawi", "Feldkirchen in Kärnten", "$63,542"],
              //   ["Mason Porter", "Chile", "Gloucester", "$78,615"]
              // ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
