import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CONFIG from "../../config.json"
import axios from "axios"
import { getToken } from "Utils/Common";
import { getUser } from "Utils/Common";
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import Tooltip from "@material-ui/core/Tooltip"
import { TextField } from "@material-ui/core"
import { removeUserSession } from "Utils/Common"
// import Rating from '@material-ui/lab/Rating';
// import Rating from 'material-ui-rating'
import StarIcon from "@material-ui/icons/Star"
import InputBase from "@material-ui/core/InputBase"


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const classes = useStyles();
  const [openDialog, setDialog] = React.useState(false)
  // const [openVote, setOpenVote] = React.useState(false)
  const [tableData, setTable] = React.useState([])
  const [listData, setListData] = React.useState([])
  const [imgPro, setImg] = React.useState({})
    const del_data = (ref) => {
      const con = window.confirm("ต้องการลบจริงหรือไม่?")
      if (!con) {
        return false
        
      }
        axios.post(`${CONFIG.backend.main_url}/admin/out_com/del/${ref}`, {}, {
            headers: {
                "auth-token": getToken()
            }
        }).then((res) => {
            window.alert("ลบสำเร็จ")
            set_table()
        })
        

  }
  const vote_api = (ref, score) => {
    let url_api = CONFIG.backend.main_url + "/user_api/out_com/"+ref+"/vote" 
    // const con = window.confirm("คุณต้องการลบโพสนี้จริงหรือไม่?")
    // if (!con) {
    //   return false
    // }
    axios.post(url_api, {
      score: score
    },{
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        alert("อัพเดตการโหวตแล้ว")
        // window.location.reload()
        set_table()
        
      }
    }).catch(async (err) => {
      if (err.response.data == "error token") {
        await removeUserSession()
        window.location.reload()
      }
    })
  }
  const getSearch = (text) => {
    // console.log(text)
    // console.log(posts)
    let outData = []
    if (text === "") {
      set_table()
      return false
    }
    let results = []
    for (let i=0 ; i < listData.length ; i++)
    {
      let myReg = new RegExp(`${text}`)
      // console.log(posts[i])
      if (listData[i].title_reward.search(myReg) !== -1) {
        results.push(listData[i]);
      } else if (listData[i].detail.search(myReg) !== -1) {
        results.push(listData[i]);
      }
       else if (listData[i].name.search(myReg) !== -1) {
        results.push(listData[i]);
      }
       else if (listData[i].timestamp.search(myReg) !== -1) {
        results.push(listData[i]);
      }
    }
    // console.log(results)
    results.map((val, index) => {
              let tmp = []
              const src_img = `${CONFIG.backend.main_url}/profile_out_com/${val.img}`
              console.log(val)
              tmp.push(<img src={src_img} width="80px" onClick={()=>{window.open(src_img)}}></img>)
              tmp.push(index+1)
              tmp.push(val.name)
              tmp.push(val.title_reward)
              
        // let voteView = ""
        // if (window.screen.width < 600) {
        //   voteView = <div><span>{Number(val.score).toFixed(2)} <StarIcon /></span><br /><span><select onChange={(e) => { vote_api(val.ref, e.target.value)}}><option  selected disabled>โหวต</option><option value={1}>1</option><option value={2}>2</option><option value={3}>3</option><option value={4}>4</option><option value={5}>5</option></select></span></div>

        // } else {
        //   voteView = <div><Rating id={"rating_" + val.ref} value={val.score} onChange={(newVal)=>{vote_api(val.ref, newVal)}} /></div>
        // }
        //     tmp.push(voteView)
            let myDelBut
              if (getUser().level > 1) {
                
                  myDelBut = (<Button color="secondary" variant="contained" onClick={()=>{del_data(val.ref)}}>ลบ</Button>)
              }
            tmp.push(<div><Button color="primary" variant="contained" href={"out_com/"+val.ref} >รายละเอียด</Button>{myDelBut }</div>)

              outData.push(tmp)
              
    })
    setTable(outData)
    // console.log(posts)
      
  }
  const set_table = () => {
      axios.get(`${CONFIG.backend.main_url}/out_com`, {
          headers: {
              "auth-token": getToken()
          }
      }).then((res) => {
        let outData = []
        setListData(res.data)
          res.data.map((val, index) => {
              let tmp = []
              const src_img = `${CONFIG.backend.main_url}/profile_out_com/${val.img}`
              console.log(val)
              tmp.push(<img src={src_img} width="80px" onClick={()=>{window.open(src_img)}}></img>)
              tmp.push(index+1)
              tmp.push(val.name)
              tmp.push(val.title_reward)
              
        // let voteView = ""
        // if (window.screen.width < 600) {
        //   voteView = <div><span>{Number(val.score).toFixed(2)} <StarIcon /></span><br /><span><select onChange={(e) => { vote_api(val.ref, e.target.value)}}><option  selected disabled>โหวต</option><option value={1}>1</option><option value={2}>2</option><option value={3}>3</option><option value={4}>4</option><option value={5}>5</option></select></span></div>

        // } else {
        //   voteView = <div><Rating id={"rating_" + val.ref} value={val.score} onChange={(newVal)=>{vote_api(val.ref, newVal)}} /></div>
        // }
        //     tmp.push(voteView)
            tmp.push(<span>{Number(val.score).toFixed(1)} ดาว / {val.vote.length} โหวต</span>)
            let myDelBut
              if (getUser().level > 1) {
                
                  myDelBut = (<Button color="secondary" variant="contained" onClick={()=>{del_data(val.ref)}}>ลบ</Button>)
              }
            tmp.push(<div><Button color="primary" variant="contained" href={"out_com/"+val.ref} >รายละเอียด</Button>{myDelBut }</div>)

              outData.push(tmp)
              
          })
          setTable(outData)
      })
      
      
  }

    const add_data = () => {
        // console.log(imgPro)
        const name = document.getElementById("name").value
        const place = document.getElementById("place").value
        const fd = new FormData()
        fd.append("name", name)
        fd.append("place", place)
        fd.append("img", imgPro)
        axios.post(`${CONFIG.backend.main_url}/admin/out_com`, fd, {
            headers: {
                "auth-token": getToken()
            }
        }).then((res) => {
            if (res.data == "success") {
                window.alert("เพิ่มข้อมูลสำเร็จ")
                setDialog(false)
                set_table()
            }
        })
      
    }
    React.useEffect(() => {
        set_table()
    },[])
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>บริษัทดีเด่น</h4>
            <p className={classes.cardCategoryWhite}>
              ตารางบริษัทดีเด่น
            </p>
            <div style={{display: "flex"}}>
            <InputBase
            style={{backgroundColor: "white", marginLeft: "auto" , borderRadius: 5}}
            fontSize="20"
                // defaultValue={search}
                onChange={(e) => {
                getSearch(e.target.value)
                
            }}
            
        placeholder="ค้นหา"
      /></div>
          </CardHeader>
          <CardBody>

                  {
                      getUser().level > 1? <div style={{display: "flex"}}><Button style={{marginLeft: "auto"}} color="primary" onClick={()=>{window.location.href = "out_com_add"}} variant="outlined">เพิ่ม</Button></div>: null
          }
            <Table
              tableHeaderColor="primary"
              tableHead={["โลโก้", "ลำดับที่", "ชื่อบริษัท", "หัวข้อรางวัลดีเด่น", "โหวต",""]}
              // tableHead={["โลโก้", "ลำดับที่", "ชื่อบริษัท", "หัวข้อรางวัลดีเด่น",""]}
              tableData={tableData}
            />
          </CardBody>
        </Card>
      </GridItem>
      <Dialog open={openDialog}>
          <DialogTitle>เพิ่มข้อมูลบริษัท รปภ ดีเด่น</DialogTitle>
              <DialogContent>

            <Tooltip title="กดเปลี่ยนรูป" aria-label="เปลี่ยนรูป">
            <div onClick={() => {
                document.getElementById("pro_image").click()
            }} style={{margin: "auto",width: "100px", height: "100px" , borderStyle: "solid"}}>
                <img id="preview_pro" width="100px" height="100px" />
            </div></Tooltip>
                        <input type="file" id="pro_image" onChange={(e) => {
                            try {
                                document.getElementById("preview_pro").src = URL.createObjectURL(e.target.files[0])
                                setImg(e.target.files[0])

                            } catch (err) {
                                console.log(err)
                            }
            }} hidden />
            <TextField label="ชื่อบริษัท" id="name" fullWidth />
            <TextField label="สถานทีตั้ง" id="place" fullWidth />


          </DialogContent>
              <DialogActions>
                  <Button color="primary" variant="contained" onClick={()=>{add_data()}}>เพิ่ม</Button>
                  <Button color="secondary" variant="contained" onClick={()=>{setDialog(false)}}>ยกเลิก</Button>
          </DialogActions>
      </Dialog>
      {/* <Dialog open={true}>
          <DialogTitle>โหวต</DialogTitle>
              <DialogContent>
                <Rating size="small" style={{colorRendering: "yellow"}} name={"rating"} value={4} precision={0.5}  />

          </DialogContent>
      </Dialog> */}
    </GridContainer>
  );
}
