import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import AddIcon from "@material-ui/icons/Add"
import InputLabel from "@material-ui/core/InputLabel";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardFooter from "components/Card/CardFooter.js";
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Tooltip from "@material-ui/core/Tooltip"
import axios from "axios"
import CONFIG from "../../config.json"
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import DelIcon from "@material-ui/icons/Delete"
import GridListTileBar from '@material-ui/core/GridListTileBar';


import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import avatar from "assets/img/faces/marc.jpg";
import { findDOMNode } from "react-dom";
import { IconButton } from "@material-ui/core";
import { checkToken } from "Utils/Common";

const styles = {
};

const useStyles = makeStyles((theme) => ({
  img_list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Mitr", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  // gridList: {
  //   height: 200,
  // },
}));

export default function TableList() {
  React.useEffect(() => {
    checkToken()

  }, [])
    const classes = useStyles();
    const [imgPro, setImgPro] = React.useState("")
    const [imgEvent, setImgEvent] = React.useState([])
  const submit = () => {
      const f_name = document.getElementById("first-name").value
      const l_name = document.getElementById("last-name").value
      const id_card = document.getElementById("num13").value
      const detail = document.getElementById("detail").value
      const place = document.getElementById("locale").value
      const date = document.getElementById("date").value

    const fd = new FormData()
    fd.append("img_profile", imgPro)
    fd.append("f_name", f_name)
    fd.append("l_name", l_name)
    fd.append("id_card", id_card)
    fd.append("detail", detail)
    fd.append("place", place)
    fd.append("date", date)
    // const eventObj = {}
    // imgEvent.map((val, index) => {
    //   eventObj[String(index)] = val
    // })
    // console.log(eventObj)
    // document.getElementById("img_event").files = eventObj
    // const myFiles = document.getElementById("img_event").files
    imgEvent.map((val, index) => {
      fd.append("img_event", val)
    })
    axios.post(CONFIG.backend.main_url + "/user_api/add_blacklist", fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        "auth-token": window.localStorage.getItem("token")
      }
    }).then((res) => {
      // console.log(res.data)
      if (res.data === "success") {
        window.alert("เพิ่มข้อมูลเรียบร้อย")
        window.location.href = "/m/blacklist"
      }
    }).catch((err) => {
      console.log(err)
    })
    }

  const delImgEvent = (index) => {
    const myList = []
    imgEvent.map((val) => {
      myList.push(val)
    })
    myList.splice(index, 1)
    setImgEvent(myList)

  }
  return (
    <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>เพิ่ม รปภ.รหัสแดง</h4>
              <p className={classes.cardCategoryWhite}>ลงทะเบียนข้อมูล รปภ.รหัสแดง</p>
            </CardHeader>
            <CardBody>
              <input id="img_event" type="file" multiple hidden />
                <GridContainer>
                <Tooltip title="กดเปลี่ยนรูป" aria-label="เปลี่ยนรูป">
                <div onClick={() => {
                    document.getElementById("pro_image").click()
                }} style={{margin: "auto",width: "100px", height: "130px" , borderStyle: "solid"}}>
                    <img id="preview_pro" width="100px" height="130px" />
                </div></Tooltip>
                          <input type="file" id="pro_image" onChange={(e) => {
                              setImgPro(e.target.files[0])
                              try {
                                document.getElementById("preview_pro").src = URL.createObjectURL(e.target.files[0])

                              } catch (err) {
                                  console.log(err)
                              }
                }} hidden />
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="ชื่อจริง"
                    id="first-name"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="นามสกุล"
                    id="last-name"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="เลขบัตรประชาชน 13 หลัก"
                    id="num13"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>

              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {/* <InputLabel style={{ color: "#AAAAAA" }}>รายละเอียด</InputLabel> */}
                  <CustomInput
                    labelText="รายละเอียดเหตุการณ์"
                    id="detail"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="สถานทีเกิดเหตุ"
                    id="locale"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <TextField
                        id="date"
                        label="วันที่เกิดเหตุ"
                        type="date"
                        // defaultValue="2017-05-24"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    {/* <TextField
                        id="image"
                        label="รูปเหตุการณ์"
                        type="file"
                        // defaultValue="2017-05-24"
                        className={classes.textField}
                    /> */}
                <Button
                  variant="contained"
                  component="label">
                    เลือกรูปเหตุการ
                  <input type="file" onChange={(e) => {
                    // e.target.files.map((val) => {
                    //   console.log(val)
                    // })
                    const myObj = e.target.files
                    const myList = []
                    imgEvent.map((val) => {
                      myList.push(val)
                    })
                    Object.keys(myObj).map((index) => {
                      // console.log(myObj[index])
                      myList.push(myObj[index])
                    })
                    // setImgEvent([])
                    console.log(myObj)
                    console.log(myList)
                    setImgEvent(myList)

                  }} multiple hidden>
                    
                  </input>
                  </Button>
                </GridItem>
                <div className={classes.img_list} >
                <GridList  >
                  {
                    imgEvent.map((val, index) => (
                      <GridListTile key={"img_"+index}>
                        <img src={URL.createObjectURL(val)} />

                        <GridListTileBar 
                          actionIcon={

                            <IconButton onClick={() => {
                              delImgEvent(index)
                          }}><DelIcon style={{color: "white"}}></DelIcon></IconButton>
                          }
                        />
                      </GridListTile>
                    ))}
                  </GridList>
                </div>
              </GridContainer>
            </CardBody>
            <CardFooter>
            <Button color="primary" onClick={() => {
              submit()
              }}>เพิ่ม</Button>
            </CardFooter>
          </Card>
        </GridItem>
    </GridContainer>
  );
}

