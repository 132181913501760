import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import CONFIG from "../config.json"
import { getToken, removeUserSession, getUser, setUserSession } from "../Utils/Common"
import {Comment as CommentIcon} from "@material-ui/icons"
// import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
// import EditIcon from '@material-ui/icons/Edit';
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Rating from '@material-ui/lab/Rating';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GridItem from "components/Grid/GridItem.js";
import logo from "../assets/img/logo.png";
import IconButton from '@material-ui/core/IconButton';
import { CameraAltSharp as CamIcon } from "@material-ui/icons"

function Copyright() {
  return (
    <Typography  color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "gray"
  },
  comment: {
    // width: '98%',
    // height: "98%",
    width: '98%',
    marginLeft: "auto",

  },
  detail: {
    
    width: "100%",
    backgroundColor: "#ebebeb",
    // color: "white"

  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  countComments: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginLeft: "auto"
  },
}));

export default function SignInSide() {
  const classes = useStyles();

  const [posts, setPosts] = React.useState([])
  
  const send_login = () => {
    const user = document.getElementById("user").value
    const pass = document.getElementById("password").value
    axios.post(CONFIG.backend.main_url + "/login", { username: user, password: pass }).then((res) => {
      setUserSession(res.data.token)
      window.location.href = "/"
    }).catch((err) => {
      console.log(err)
      window.alert("รหัสผิดพลาด")
    })
  }
  
  const set_posts = () => {
    axios.get(CONFIG.backend.main_url + "/top_posts").then((res) => {
      setPosts(res.data)
    })
    
  }

  React.useEffect(() => {
    set_posts()

  },[])
  

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} style={{backgroundColor: "rgb(202 201 201)"}} square>
        <div className={classes.paper}>
          {/* <Avatar className={classes.avatar}> */}
            {/* <LockOutlinedIcon /> */}
          <img src={logo} style={{width: 80}} />
          {/* </Avatar> */}
          {/* <Typography component="h1" variant="h5">
            เข้าสู่ระบบ
          </Typography> */}
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="user"
              label="ชื่อผู้ใช้"
              name="user"
              autoComplete="user"
              variant="filled"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="รหัสผ่าน"
              type="password"
              id="password"
              variant="filled"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
            //   type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => {
                send_login()
              }}
            >
              เข้าสู่ระบบ
            </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link style={{color: "gray"}} href="/regis">
                สมัครสมาชิก
              </Link>
            </Grid>
          </Grid>

            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            {/* <Box mt={5}>
              <Copyright />
            </Box> */}
          </form>
          <div style={{ overflowY: "auto", height: "250px", display: posts.length==0? "none": "block"}}>
        <div>กระทู้ยอดนิยม</div>
        {
          posts.map((val, myI) => {
            return (<Accordion style={{backgroundColor: "#E8E5E4", borderRadius: "4px"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={"header_"+val.id}
        >
          <div>
                <Typography className={classes.heading} style={{wordBreak: "break-all"}}><b>เรื่อง: </b>{ val.title}</Typography>
                <Typography className={classes.secondaryHeading}><small><b>โพสโดย: </b>{ val.from_user }</small></Typography>
                <Rating style={{marginLeft: "auto"}} size="small" name="rating" precision={0.5} value={val.score} readOnly />
                <small style={{color: "gray"}}>{
                  "\t "+val.score
                } ดาว / {val.vote.length} โหวต</small>

                <Typography className={classes.countComments}><CommentIcon />{ val.comments.length }</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Card className={classes.root}> */}
                <List style={{width: "100%"}}>
                  <ListItem>
            <Card className={classes.detail}>
              <CardContent>
         <Typography><b>เนื้อหา:</b>
                  {/* <CardContent>  */}
                  {
                    " "+val.detail
            }
            {/* </CardContent>
          </Card> */}</Typography>

                      </CardContent>
          <div>
  </div>
          </Card>
          </ListItem>
          <ListItem>
            <List className={classes.comment}>
              {
                val.comments.map((val1, comI) => {
                  return <ListItem><Card className={classes.comment}>
              <CardContent>
                  <small style={{color: "gray"}}>โดย: { val1.from_user} </small><br />

                      <div><span style={{ color: "gray" }}>ความคิดเห็น: </span>{val1.comment}<br />{val1.img.length !== 0 ? <img width="150" src={`${CONFIG.backend.main_url}/comment/${val1.img}`} onClick={() => {
                        window.open(`${CONFIG.backend.main_url}/comment/${val1.img}`)
                      }}></img> : null } </div>
                  {/* <span style={{color: "gray"}}>ความคิดเห็น: </span>{val1.comment} */}
              </CardContent>

                    <small style={{ color: "gray", marginLeft: "auto" }}>ความคิดเห็นที่ {comI + 1}</small>
              </Card></ListItem>
                })
        }
        </List>
        </ListItem>
        </List>
        </AccordionDetails>
          {/* <AccordionDetails>
          <Card className={classes.comment}>
            <CardContent>
                โดย: krittakon <br />
                ความคิดเห็น: ทดสอบ
            </CardContent>
            </Card>
        </AccordionDetails> */}
              <AccordionActions>
                {/* <TextField id={"msg_comment"+val.id} label="ความคิดเห็น" fullWidth /><Button color="primary" onClick={() => {
                  send_comment(val.id)
                }}>ส่ง</Button> */}
                <IconButton onClick={() => {
                  window.location.href = "/regis"
                }} >
                  <CamIcon />
                </IconButton>
                <TextField id={"msg_comment"+val.id} label="ความคิดเห็น" fullWidth /><Button color="primary" variant="outlined" onClick={() => {
                  window.location.href = "/regis"
                }}>ส่ง</Button>
        </AccordionActions>

      </Accordion>)

          })
        }
 

</div>

        </div>
      </Grid>
    </Grid>
  );
}