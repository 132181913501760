import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from "axios";
import CONFIG from "../config.json";
import logo from "../assets/img/logo.png";

function Copyright() {
  return (
    <Typography color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const send_regis = () => {
    const user = document.getElementById("username").value
    const pass = document.getElementById("password").value
    const email = document.getElementById("email").value
    const phone = document.getElementById("phone").value
    const repass = document.getElementById("repassword").value
    const f_name = document.getElementById("firstName").value
    const l_name = document.getElementById("lastName").value
    if (pass !== repass) {
        window.alert("รหัสผ่านทั้งสองไม่ตรงกัน")
        return false
    }
    
    axios.post(CONFIG.backend.main_url + "/regis", {
        user: user,
        pass: pass,
        email: email,
        phone: phone,
        f_name: f_name,
        l_name: l_name
    }).then((res) => {
      window.alert("สมัครสมาชิกเรียบร้อย")
        
      window.location.href = "/"
    }).catch((err) => {
      console.log(err)
      window.alert("เกิดข้อผิดพลาด")
    })
  }

  React.useEffect(() => {

    document.getElementsByTagName("body")[0].style = "background-color: rgb(202 201 201);"
  }, [])
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <img src={logo} width="100%" />
          <LockOutlinedIcon /> 
        </Avatar> */}
        {/* <Typography component="h1" variant="h5"> */}
        <h3>
          สมัครสมาชิก
        </h3>
        {/* </Typography> */}
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="ชื่อ"
                variant="filled"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="นามสกุล"
                name="lastName"
                variant="filled"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="username"
                label="ชื่อผู้ใช้"
                name="username"
                variant="filled"
                autoComplete="username"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="อีเมล"
                name="email"
                variant="filled"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="phone"
                label="เบอร์โทร"
                name="phone"
                variant="filled"
                autoComplete="phone"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="รหัสผ่าน"
                type="password"
                id="password"
                variant="filled"
                autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                variant="filled"
                name="repassword"
                label="รหัสผ่านอีกครั้ง"
                type="password"
                id="repassword"
                autoComplete="current-password"
              />
            </Grid>
          </Grid>
          <Button
            // type="submit"
                      onClick={() => {
                          send_regis()
            }}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            style={{backgroundColor: "gray"}}
          >
            สมัคร
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/login" style={{color: "gray"}}>
                เข้าสู่ระบบ
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      {/* <Box mt={5}>
        <Copyright />
      </Box> */}
    </Container>
  );
}