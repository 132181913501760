import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Dialog, DialogTitle, DialogContent, TextField } from "@material-ui/core"
import CONFIG from "../../config.json"
import axios from "axios"
import { checkToken } from "Utils/Common";
import { useHistory, useLocation } from "react-router-dom"
import Button from "@material-ui/core/Button"
import qs from "query-string"
import InputBase from '@material-ui/core/InputBase';
// core components

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function TableList() {
    const classes = useStyles();
    const [openDialog, setDialogOpen] = React.useState(false)
    const [dialogData, setDialogData] = React.useState({img_event: []})
    const [blacklist, setBlacklist] = React.useState([])
    const loca = useLocation();
    const myQs = qs.parse(loca.search)
    
    console.log("params", myQs)

  const search = myQs.q || ""
  const getSearch = (search) => {
    axios.get(CONFIG.backend.main_url + "/user_api/blacklist?q="+search, {
      headers: {
          "auth-token": window.localStorage.getItem("token")
        }
    }).then((res) => {
      let myList = []
      res.data.map((val, index) => {
        let tmp = []
        tmp.push(index+1)
        tmp.push(val.f_name)
        tmp.push(val.l_name)
        tmp.push(val.detail)
        // tmp.push(<button>ลบ</button>)
        tmp.push(<Button color="primary" onClick={() => {
          setDialogData(val)
          setDialogOpen(true)
        }}>รายละเอียด</Button>)
        myList.push(tmp)
      })
      setBlacklist(myList)
      })

  }
  React.useEffect(() => {
    // const search = myQs.q || ""
    
    

    checkToken()
    getSearch(search)
    },[])

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>รปภ.รหัสแดง</h4>
            <div style={{display: "flex"}}>
            <p className={classes.cardCategoryWhite}>
            ข้อมูล รปภ. ที่ลงทะเบียน รหัสแดง
            </p>
              {/* <TextField style={{ marginLeft: "auto" }} size="small" variant="filled" label="ค้นหา"
               defaultValue={search} onChange={(e) => {
                getSearch(e.target.value)
                
            }}
            ></TextField> */}
            <InputBase
            style={{backgroundColor: "white", marginLeft: "auto" , borderRadius: 5}}
            fontSize="20"
                defaultValue={search}
                onChange={(e) => {
                getSearch(e.target.value)
                
            }}
            
        placeholder="ค้นหา"
      />
            </div>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["ลำดับที", "ชื่อ", "นามสกุล", "รายละเอียดเหตุการณ์", "ดูรายละเอียด"]}
              tableData={blacklist}
              // tableData={[
              //   [1, "สมชาย", "น้ำดี", "ขโมยของในหน่วยงาน", <button>ดูรายละเอียด</button>],
              //   [2, "สมทุย", "น้ำใจ", "ขโมยของในหน่วยงาน", <button>ดูรายละเอียด</button>],
              // ]}
            />
          </CardBody>
        </Card>
      </GridItem>
      <Dialog open={openDialog} onClose={() => {
        setDialogOpen(false)
      }}>
        <DialogTitle>รายละเอียด รปภ รหัสแดง</DialogTitle>
        <DialogContent dividers>
          <img src={`${CONFIG.backend.main_url}/profile_blacklist/${dialogData.img_profile}`} width="100px" height="120px"></img><br />
          <span><b>ชื่อ:</b> { dialogData.f_name }</span><br />
          <span><b>นามสกุล:</b> {dialogData.l_name}</span><br />
          <span><b>เลขบัตรประชาชน:</b> {dialogData.id_card}</span><br />
          <span><b>สถานที่:</b></span> {dialogData.place }<br />
          <span><b>วันที่:</b></span> {String(dialogData.date).split("T")[0]}<br />
          <span><b>รายละเอียด:</b></span> {dialogData.detail}<br />
          <div>
            <span><b>รูปเหตุการณ์: </b>{dialogData.img_event.length ===0? "ไม่มี" : null}</span>
          <br />
            {
              dialogData.img_event.map((val) => {
                return <img width="200px" src={`${CONFIG.backend.main_url}/event_blacklist/${ val.img }`} onClick={(e) => {
                  window.open(e.target.src)
                }} />
              })
            }
          </div>
        </DialogContent>

      </Dialog>

    </GridContainer>
  );
}
