import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import AddIcon from "@material-ui/icons/Add"
import InputLabel from "@material-ui/core/InputLabel";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardFooter from "components/Card/CardFooter.js";
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Tooltip from "@material-ui/core/Tooltip"
import secure_icon from "../../assets/img/secure_icon.png"
import { Visibility as EyesIcon, AddCircle as AddCIcon } from "@material-ui/icons"
import IconButton from "@material-ui/core/IconButton"


import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import avatar from "assets/img/faces/marc.jpg";
import { checkToken } from "Utils/Common";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function TableList() {
  React.useEffect(() => {
    checkToken()
  })
    const classes = useStyles();
    const [imgPro, setImgPro] = React.useState("")
  return (
    <GridContainer>

        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success">
              <img src={secure_icon} width="150" />
              {/* <ChartistGraph
                className="ct-chart"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              /> */}
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>ตาราง รปภ รหัสแดง</h4>
              {/* <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p> */}
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{margin: "auto"}}>
            <IconButton onClick={() => {
              window.location.href = "/m/search"
              }}><EyesIcon fontSize="large"></EyesIcon>ดู</IconButton>
              </div>
            </div>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                {/* <AccessTime /> updated 4 minutes ago */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <img src={secure_icon} width="150" />
              {/* <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              /> */}
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>เพิ่ม รปภ รหัสแดง</h4>
              {/* <p className={classes.cardCategory}>Last Campaign Performance</p> */}
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{margin: "auto"}}>
            <IconButton onClick={() => {
              window.location.href = "/m/blacklist_add"
              }}><AddCIcon fontSize="large"></AddCIcon>เพิ่ม</IconButton>
            </div>
            </div>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                {/* <AccessTime /> campaign sent 2 days ago */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        
    </GridContainer>
  );
}
