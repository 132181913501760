import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import axios from "axios"
import CONFIG from "../../config.json"
import { getToken, removeUserSession, getUser } from "../../Utils/Common"
import Card from '@material-ui/core/Card';
import CardC from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardContent from '@material-ui/core/CardContent';
import {Comment as CommentIcon} from "@material-ui/icons"
import {Button } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Rating from '@material-ui/lab/Rating';
import { CameraAltSharp as CamIcon } from "@material-ui/icons"
import InputBase from "@material-ui/core/InputBase"


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  comment: {
    // width: '98%',
    // height: "98%",
    width: '98%',
    marginLeft: "auto",

  },
  detail: {
    
    width: "100%",
    backgroundColor: "#ebebeb",
    // color: "white"

  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  countComments: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginLeft: "auto"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
}));

export default function TableList() {
  const classes = useStyles();
  const [posts, setPosts] = React.useState([])
  const [all_posts, setAllPosts] = React.useState([])
  const [img_comment, setImg_comment] = React.useState({})

  // const [profile, setProfile] = React.useState(getUser())

  const [openDialog, setOpenDialog] = React.useState(false)
  const [openEdit, setOpenEdit] = React.useState(false)
  const [editPost, setEditPost] = React.useState({title: "", detail: ""})
  const set_posts = () => {
    axios.get(CONFIG.backend.main_url + "/user_api/post", {
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      let myData = res.data.reverse()
      setAllPosts(myData)
      setPosts(myData)
    }).catch(async (err) => {
      if (err.response.data == "error token") {
        await removeUserSession()
        window.location.reload()
      }
    })
    
  }
  const get_comment = async (post_id) => {
    let myList = await axios.get(CONFIG.backend.main_url + "/user_api/post/"+post_id+"/comments", {
      headers: {
        "auth-token": getToken()
      }
    }).catch(async (err) => {
      if (err.response.data == "error token") {
        await removeUserSession()
        window.location.reload()
      }
    })
    myList = myList.reverse()
    return myList
  }
  const send_post = () => {
    const title = document.getElementById("title_post").value
    const detail = document.getElementById("detail_post").value
    axios.post(CONFIG.backend.main_url + "/user_api/post", {
      title: title,
      detail: detail
    },{
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        // alert("โพสกระทู้แล้ว")
        setOpenDialog(false)
        // window.location.reload()
        set_posts()
        
      }
    }).catch(async (err) => {
      if (err.response.data == "error token") {
        await removeUserSession()
        window.location.reload()
      }
    })

    
  }
  const send_comment = (post_id) => {
    const comment = document.getElementById("msg_comment"+post_id).value
    const fd = new FormData()
    fd.append("comment", comment);
    if (img_comment.length > 0) {
      fd.append("img", img_comment[0])

    }
    axios.post(CONFIG.backend.main_url + "/user_api/post/"+post_id+"/comment", fd,{
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        // alert("แสดงความคิดเห็นแล้ว")
        document.getElementById("img_comment").value = ""
        setImg_comment([])
        document.getElementById("msg_comment"+post_id).value = ""
        // window.location.reload()
        set_posts()
        
      }
    }).catch(async (err) => {
      if (err.response.data == "error token") {
        await removeUserSession()
        window.location.reload()
      }
    })

  }
  const send_edit = (post_id) => {
    const title = document.getElementById("title_edit").value
    const detail = document.getElementById("detail_edit").value
    axios.post(CONFIG.backend.main_url + "/user_api/post/"+post_id+"/edit", {
      title: title,
      detail: detail
    },{
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        // alert("แก้ไข้โพสแล้ว")
        // window.location.reload()
        setOpenDialog(false)

        set_posts()
        
      }
    }).catch(async (err) => {
      if (err.response.data == "error token") {
        await removeUserSession()
        window.location.reload()
      }
    })

  }
  const del_post = (post_id) => {

    const con = window.confirm("คุณต้องการลบโพสนี้จริงหรือไม่?")
    let url_api = CONFIG.backend.main_url + "/user_api/post/"+post_id+"/del"
    if (getUser().level > 1) {
      url_api = CONFIG.backend.main_url + "/admin/post/"+post_id+"/del"
    }
    if (!con) {
      return false
    }
    axios.post(url_api, {
    },{
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        // alert("ลบโพสแล้ว")
        // window.location.reload()
        set_posts()
        
      }
    }).catch(async (err) => {
      if (err.response.data == "error token") {
        await removeUserSession()
        window.location.reload()
      }
    })
  }
  const vote_post = (post_id, score) => {
    let url_api = CONFIG.backend.main_url + "/user_api/post/"+post_id+"/vote" 
    // const con = window.confirm("คุณต้องการลบโพสนี้จริงหรือไม่?")
    // if (!con) {
    //   return false
    // }
    axios.post(url_api, {
      score: score
    },{
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        // alert("ลบความคิดเห็นแล้ว")
        // window.location.reload()
        set_posts()
        
      }
    }).catch(async (err) => {
      if (err.response.data == "error token") {
        await removeUserSession()
        window.location.reload()
      }
    })
  }
  const del_comment = (post_id, comment_id) => {
    let url_api = CONFIG.backend.main_url + "/user_api/post/"+post_id+"/comment/"+comment_id+"/del" 
    if (getUser().level > 1) {
      url_api = CONFIG.backend.main_url + "/admin/post/"+post_id+"/comment/"+comment_id+"/del" 
    }
    const con = window.confirm("คุณต้องการลบโพสนี้จริงหรือไม่?")
    if (!con) {
      return false
    }
    axios.post(url_api , {
    },{
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        // alert("ลบความคิดเห็นแล้ว")
        alert("อัพเดตการโหวตแล้ว")
        // window.location.reload()
        set_posts()
        
      }
    }).catch(async (err) => {
      if (err.response.data == "error token") {
        await removeUserSession()
        window.location.reload()
      }
    })
  }

  
  React.useEffect(() => {
    set_posts()
  }, [])
  const convertTime = (myTime) => {
    let m = new Date(myTime)
    let dateString = m.getUTCFullYear() +"/"+ (m.getUTCMonth()+1) +"/"+ m.getUTCDate() + " " + m.getUTCHours() + ":" + m.getUTCMinutes() ;
    return dateString

  }
  const getSearch = (text) => {
    // console.log(text)
    // console.log(posts)
    if (text === "") {
      setPosts(all_posts)
      return false
    }
    let results = []
    for (let i=0 ; i < posts.length ; i++)
    {
      let myReg = new RegExp(`${text}`)
      // console.log(posts[i])
      if (all_posts[i].title.search(myReg) !== -1) {
        results.push(all_posts[i]);
      } else if (all_posts[i].detail.search(myReg) !== -1) {
        results.push(all_posts[i]);
      } else if (all_posts[i].from_user.search(myReg) !== -1) {
        results.push(all_posts[i]);
      } else if (all_posts[i].timestamp.search(myReg) !== -1) {
        results.push(all_posts[i]);
      }
    }
    // console.log(results)
    setPosts(results)
    // console.log(posts)
      
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardC>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>กระทู้</h4>
            <p className={classes.cardCategoryWhite}>
            กระทู้สำหรับ ถาม-ตอบ
            </p>
            <div style={{display: "flex"}}>
            <InputBase
            style={{backgroundColor: "white", marginLeft: "auto" , borderRadius: 5}}
            fontSize="20"
                // defaultValue={search}
                onChange={(e) => {
                getSearch(e.target.value)
                
            }}
            
        placeholder="ค้นหา"
      /></div>
          </CardHeader>
          <CardBody>
            <div style={{display: "flex"}}>
        <Button style={{
          marginLeft: "auto",
          marginRight: "10px"
      }} color="primary" variant="outlined" onClick={() => {
          setOpenDialog(true)
        }}>โพสกระทู้</Button>

            </div>
        <p></p>
        {
          posts.map((val, myI) => {
            return (<Accordion style={{backgroundColor: "#E8E5E4", borderRadius: "4px"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={"header_"+val.id}
        >
          <div>
                <Typography className={classes.heading} style={{wordBreak: "break-all"}}><b>เรื่อง: </b><span style={{color: "gray"}}>{ val.title}</span></Typography>
                <Typography className={classes.secondaryHeading}><small><b>โพสโดย: </b>{ getUser().username === val.from_user? "คุณ": val.from_user }</small></Typography>
                <Typography className={classes.secondaryHeading} style={{ margin: "auto" }}><small><b>เวลา: </b>{
                  convertTime(val.timestamp)
                }</small></Typography>
                <Rating style={{marginLeft: "auto"}} size="small" name="rating" precision={0.5} value={val.score} readOnly />
                <small style={{color: "gray"}}>{
                  "\t "+val.score
                } ดาว / {val.vote.length} โหวต</small>
                <Typography className={classes.countComments}>{ val.comments.length }</Typography><CommentIcon />
        </div>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Card className={classes.root}> */}
                <List className={classes.root}>
                  <ListItem>
            <Card className={classes.detail}>
              <CardContent>
         <Typography><b>เนื้อหา:</b>
                  {/* <CardContent>  */}
                  <span>
                  {
                    " "+val.detail
            }
                </span>
            {/* </CardContent>
          </Card> */}</Typography>

                      </CardContent>
          <div>
                    {
                    getUser().username === val.from_user || getUser().level > 1 ? <IconButton aria-label="delete" className={classes.margin} onClick={() => {
                      del_post(val.id)
                      }}>
                        <DeleteIcon fontSize="small" />
                        </IconButton> : null
                }
                      {
                          getUser().username === val.from_user ? <IconButton aria-label="edit" className={classes.margin} onClick={() => {
                        setEditPost({title: val.title, id: val.id, detail: val.detail})
                        setOpenEdit(true)
                      }}>
                          <EditIcon fontSize="small" />
                  </IconButton> : null

                }
                  <Rating id={"vote_rate_"+val.id} value={null} onChange={(e) => { vote_post(val.id, e.target.value); console.log(val) }} />
  </div>
          </Card>
          </ListItem>
          <ListItem>
            <List className={classes.comment}>
              {
                val.comments.map((val1, comI) => {
                  return <ListItem><Card className={classes.comment}>
              <CardContent>
                  <small style={{color: "gray"}}>โดย: {getUser().username === val1.from_user? "คุณ": val1.from_user}</small><br />
                      <small style={{ color: "gray" }}>
                        <b>เวลา: </b>{convertTime(val1.timestamp)}
                      </small>
                      <div><span style={{ color: "gray" }}>ความคิดเห็น: </span>{val1.comment}<br />{val1.img.length !== 0 ? <img width="150" src={`${CONFIG.backend.main_url}/comment/${val1.img}`} onClick={() => {
                        window.open(`${CONFIG.backend.main_url}/comment/${val1.img}`)
                      }}></img> : null } </div>
                      <br />
              </CardContent>
                    {
                        getUser().username === val1.from_user || getUser().level > 1 ? <div><IconButton aria-label="delete" className={classes.margin} onClick={() => {
                          del_comment(val.id, val1.id)
                      }}>
          <DeleteIcon fontSize="small" />
        </IconButton></div> : null
                    }
                    <small style={{ color: "gray", marginLeft: "auto" }}>ความคิดเห็นที่ {comI + 1}</small>
              </Card></ListItem>
                })
        }
        </List>
        </ListItem>
        </List>
        </AccordionDetails>
          {/* <AccordionDetails>
          <Card className={classes.comment}>
            <CardContent>
                โดย: krittakon <br />
                ความคิดเห็น: ทดสอบ
            </CardContent>
            </Card>
        </AccordionDetails> */}
                {
                img_comment.length > 0 ? <div style={{width: 80, margin: "auto"}}>
                  <img width="100%" src={URL.createObjectURL(img_comment[0])} />
                  <button onClick={()=>{
                    document.getElementById("img_comment").value = null
                    setImg_comment([])
                  }}>X</button>
                </div> : null
                  
                }
              <AccordionActions>
                <input type="file" id="img_comment" accept="image/*" onChange={(e) => {
                  setImg_comment(e.target.files)
                }} hidden></input>
                <IconButton onClick={() => {
                  document.getElementById("img_comment").click()
                }} >
                  <CamIcon />
                </IconButton>
                <TextField id={"msg_comment"+val.id} label="ความคิดเห็น" fullWidth /><Button color="primary" variant="outlined" onClick={() => {
                  send_comment(val.id)
                }}>ส่ง</Button>
        </AccordionActions>

      </Accordion>)

          })
              }
            </CardBody>
            </CardC>
      </GridItem>
      <Dialog open={openDialog} onClose={() => {
        setOpenDialog(false)
      }}>
        <DialogTitle>
          โพสกระทู้
        </DialogTitle>
        <DialogContent>
          <TextField id="title_post" label="เรื่อง" fullWidth />
          <TextField id="detail_post" label="รายละเอียด" rows={4} fullWidth multiline  />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => {
            send_post()
          }}>โพส</Button>

        </DialogActions>
      </Dialog>
      <Dialog open={openEdit} onClose={() => {
        setOpenEdit(false)
      }}>
        <DialogTitle>
          แก้ไขกระทู้
        </DialogTitle>
        <DialogContent>
          <TextField defaultValue={editPost.title} id="title_edit" label="เรื่อง" fullWidth />
          <TextField defaultValue={editPost.detail} id="detail_edit" label="รายละเอียด" rows={4} fullWidth multiline  />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => {
            send_edit(editPost.id)
          }}>แก้ไข</Button>

        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}
