import { Button } from "@material-ui/core"
import React from "react"
import TextField from "@material-ui/core/TextField"
import Container from '@material-ui/core/Container';
import logo from "../../assets/img/logo.png";

const center = {
    // margin: "auto",
    width: "100%",
    // border: "3px solid green",
    // padding: "10px",
    height: "100%",
    // display: "flex",
    justifyContent: "center",
    // backgroundColor: "#9d0b0e"
}
export default class Search extends React.Component{
    render() {
        return <div style={center}><Container style={{
            display: "table",
            margin: "0 auto",
            justifyContent: "center",
            top: 40

        }}

            maxWidth="sm">
            <center>
            <img src={logo} width={80} style={{backgroundColor: "#9d0b0e", borderRadius: 25}} />
            </center>
            <TextField id="textSearch" label="คำค้นหา" fullWidth>search</TextField>
            <center><Button color="primary" onClick={() => {
                const search = document.getElementById("textSearch").value
                window.location.href = `/m/blacklist_show?q=${search}`
            }}
                style={{ backgroundColor: "gray", top: 10 }}
              variant="contained"
            >ค้นหา</Button></center></Container>
        </div>
    }
}