import jwt from "jsonwebtoken"
import axios from "axios"
import CONFIG from "../config.json"
// return the user data from the session storage

export const getUser = () => {
  const user = jwt.decode(getToken())
  if (user) {
    return user
  } else {
    return null
  }
  
}
 
// return the token from the session storage
export const getToken = () => {
  // return sessionStorage.getItem('token') || null;
  return localStorage.getItem('token') || null;
}
 
export const checkToken = () => {
  axios.get(CONFIG.backend.main_url + "/user_api", {
    headers: {
    "auth-token": getToken()
    }
  }).then(res => {
    if (res.data == "success") {
      return true
    }
  }).catch((err) => {
    if (err.response.data == "error token") {
      removeUserSession()
      window.location.href = "/login"
    }
  })
}
// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('token');
  // sessionStorage.removeItem('token');
  // sessionStorage.removeItem('user');
}

 
// set the token and user from the session storage
export const setUserSession = (token) => {
  localStorage.setItem('token', token);
  // sessionStorage.setItem('token', token);
  // sessionStorage.setItem('user', JSON.stringify(user));
}

export const get_profile = async () => {
  try {
    const result = await axios.get(`${CONFIG.backend.main_url}/user_api/profile`,{
      headers:
      {
        "auth-token": getToken()
      }
    })
    return result.data

  } catch (err) {
    console.log(err)
    return false
  }
    
  // sessionStorage.setItem('token', token);
  // sessionStorage.setItem('user', JSON.stringify(user));
}