import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CONFIG from "../../config.json"
import axios from "axios"
import { getToken } from "Utils/Common";
import { Button, TextField } from "@material-ui/core";
import { Dialog, DialogActions, DialogTitle, DialogContent } from "@material-ui/core"
import InputBase from "@material-ui/core/InputBase"

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const [jsonTable, setJson] = React.useState([])
  const [dataTable, setData] = React.useState([])
  const [newMem, setOpenNew] = React.useState(false)
  const classes = useStyles();
  const setUsers = () => {
    axios.get(`${CONFIG.backend.main_url}/admin/users`, {
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      setJson(res.data)
      const myData = []
      res.data.map((val) => {
        const tmp = []
        tmp.push(val.username)
        tmp.push(val.f_name)
        tmp.push(val.l_name)
        tmp.push(val.email)
        tmp.push(val.phone)
        let status = val.level
        if (status == 0) {
          status = "แบน"
        }
        else if (status == 1) {
          status = "สมาชิก"

        } else if (status == 2) {
          status = "แอดมิน"
        } else if (status == 3) {
          status = "root"
        }
        tmp.push(status)
        if (val.level == 0) {
          tmp.push(<Button color="primary" variant="contained" onClick={()=>{toMem(val.username)}}>ปลดแบน</Button>)
        } else if(val.level < 3){
          tmp.push(<Button color="secondary" variant="contained" onClick={()=>{toBan(val.username)}}>แบน</Button>)
        } else {
          tmp.push("")
        }
        if (val.level == 2) {
          tmp.push(<Button color="secondary" variant="contained" onClick={()=>{toMem(val.username)}}>ปลดแอดมิน</Button>)
        } else if(val.level == 1 ){
          tmp.push(<Button style={{color: "white", backgroundColor: "green"}} onClick={()=>{toAdmin(val.username)}}>เพิ่มแอดมิน</Button>)
        } else {
          tmp.push("")
        }
        myData.push(tmp)
        
      })
      setData(myData)
      
    })
  }
  const toAdmin = (user) => {
    const api_path = `/admin/user/${user}/add_admin`
    axios.post(`${CONFIG.backend.main_url}${api_path}`, {}, {
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        window.alert("อัพเดตเรียบร้อย")
        setUsers()
      }
    })
  }
  const toMem = (user) => {
    const api_path = `/admin/user/${user}/per_mem`
    axios.post(`${CONFIG.backend.main_url}${api_path}`, {}, {
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        window.alert("อัพเดตเรียบร้อย")
        setUsers()
      }
    })
  }
  const toBan = (user) => {
    const api_path = `/admin/user/${user}/ban`
    axios.post(`${CONFIG.backend.main_url}${api_path}`, {}, {
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        window.alert("อัพเดตเรียบร้อย")
        setUsers()
      }
    })
  }
  const add_newUser = () => {
    const user = document.getElementById("add_user").value
    const pass = document.getElementById("add_pass").value
    const repass = document.getElementById("add_repass").value
    const f_name = document.getElementById("add_fName").value
    const l_name = document.getElementById("add_lName").value

    if (pass !== repass) {
      console.log(pass, repass)
      window.alert("โปรดกรอกรหัสผ่านทั้งสองช่องให้ตรงกัน!")
      return false
    }
    axios.post(`${CONFIG.backend.main_url}/admin/user/add`, {
      user: user,
      pass: pass,
      f_name: f_name,
      l_name: l_name
    }, {
        headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        window.alert("เพิ่มสมาชิกเรียบร้อย")
        setUsers()
      }

    }).catch((err) => {
      console.log(err)
      window.alert("ผิดพลาด")
    })
  }
  React.useEffect(() => {
    setUsers()

  }, [])
  const getSearch = (text) => {
    // console.log(text)
    // console.log(jsonTable)
    let results = []
    for (let i=0 ; i < jsonTable.length ; i++)
    {
      let myReg = new RegExp(`${text}`)
      // console.log(posts[i])
      if (jsonTable[i].username.search(myReg) !== -1) {
        results.push(jsonTable[i]);
      } else if (jsonTable[i].email.search(myReg) !== -1) {
        results.push(jsonTable[i]);
      } else if (jsonTable[i].phone.search(myReg) !== -1) {
        results.push(jsonTable[i]);
      } else if (jsonTable[i].f_name.search(myReg) !== -1) {
        results.push(jsonTable[i]);
      } else if (jsonTable[i].l_name.search(myReg) !== -1) {
        results.push(jsonTable[i]);
      }
    }
    // console.log(results)
    const myData = []
    results.map((val, index) => {
      
        const tmp = []
        tmp.push(val.username)
        tmp.push(val.f_name)
        tmp.push(val.l_name)
        tmp.push(val.email)
        tmp.push(val.phone)
        let status = val.level
        if (status == 0) {
          status = "แบน"
        }
        else if (status == 1) {
          status = "สมาชิก"

        } else if (status == 2) {
          status = "แอดมิน"
        } else if (status == 3) {
          status = "root"
        }
        tmp.push(status)
        if (val.level == 0) {
          tmp.push(<Button color="primary" variant="contained" onClick={()=>{toMem(val.username)}}>ปลดแบน</Button>)
        } else if(val.level < 3){
          tmp.push(<Button color="secondary" variant="contained" onClick={()=>{toBan(val.username)}}>แบน</Button>)
        } else {
          tmp.push("")
        }
        if (val.level == 2) {
          tmp.push(<Button color="secondary" variant="contained" onClick={()=>{toMem(val.username)}}>ปลดแอดมิน</Button>)
        } else if(val.level == 1 ){
          tmp.push(<Button style={{color: "white", backgroundColor: "green"}} onClick={()=>{toAdmin(val.username)}}>เพิ่มแอดมิน</Button>)
        } else {
          tmp.push("")
        }
        myData.push(tmp)
        
    })

    setData(myData)
    // console.log(posts)
      
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>สมาชิกทั้งหมด</h4>
            <p className={classes.cardCategoryWhite}>
              จัดการสมาชิกทั้งหมด
            </p>
            <div style={{display: "flex"}}>
            <InputBase
            style={{backgroundColor: "white", marginLeft: "auto" , borderRadius: 5}}
            fontSize="20"
                // defaultValue={search}
                onChange={(e) => {
                getSearch(e.target.value)
                
            }}
            
        placeholder="ค้นหา"
      /></div>
          </CardHeader>
          <CardBody>
            <div style={{display: "flex"}} >
              <Button style={{ marginLeft: "auto" }} color="primary" variant="outlined" onClick={() => {
                setOpenNew(true)
              }}>เพิ่มสมาชิก</Button>
            </div>
            <Table
              tableHeaderColor="primary"
              tableHead={["ชื่อผู้ใช้", "ชื่อ", "นามสกุล", "อีเมล", "เบอร์โทร", "สถานะ", "", ""]}
              tableData={dataTable}
            />
          </CardBody>
        </Card>
      </GridItem>

      <Dialog open={newMem} onClose={() => {
        setOpenNew(false)
      }}>
        <DialogTitle>
          เพิ่มสมาชิกใหม่
        </DialogTitle>

        <DialogContent>
          <TextField label="ชื่อผู้ใช้" id="add_user" fullWidth />
          <TextField label="รหัสผ่าน" id="add_pass" type="password" fullWidth />
          <TextField label="รหัสผ่านอีกครั้ง" id="add_repass" type="password" fullWidth />
          <TextField label="ชื่อ" id="add_fName" fullWidth />
          <TextField label="นามสกุล" id="add_lName" fullWidth />
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={()=>{setOpenNew(false)}}>ยกเลิก</Button>
          <Button color="primary" onClick={() => {
            add_newUser()
          }}>เพิ่ม</Button>
        </DialogActions>

      </Dialog>
      {/* <GridItem xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader plain color="primary">
            <h4 className={classes.cardTitleWhite}>
              Table on Plain Background
            </h4>
            <p className={classes.cardCategoryWhite}>
              Here is a subtitle for this table
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["ID", "Name", "Country", "City", "Salary"]}
              tableData={[
                ["1", "Dakota Rice", "$36,738", "Niger", "Oud-Turnhout"],
                ["2", "Minerva Hooper", "$23,789", "Curaçao", "Sinaai-Waas"],
                ["3", "Sage Rodriguez", "$56,142", "Netherlands", "Baileux"],
                [
                  "4",
                  "Philip Chaney",
                  "$38,735",
                  "Korea, South",
                  "Overland Park"
                ],
                [
                  "5",
                  "Doris Greene",
                  "$63,542",
                  "Malawi",
                  "Feldkirchen in Kärnten"
                ],
                ["6", "Mason Porter", "$78,615", "Chile", "Gloucester"]
              ]}
            />
          </CardBody>
        </Card>
      </GridItem> */}
    </GridContainer>
  );
}
