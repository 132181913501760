import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Card as CardM, CardBody as CardMBody } from "@material-ui/core"
import axios from "axios";
import CONFIG from "../../config.json"
import { getToken, checkToken, getUser } from "../../Utils/Common"
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button"
import { Add as AddIcon } from "@material-ui/icons"
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import DelIcon from "@material-ui/icons/Delete"
import GridListTileBar from '@material-ui/core/GridListTileBar';
import InputBase from "@material-ui/core/InputBase"
// core components

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function TableList() {
    const classes = useStyles();
    // const [imgPro, setImgPro] = React.useState("")
    const [reported, setReported] = React.useState([])
    const [listReported, setReportedList] = React.useState([])
    const [openDialog, setDialogOpen] = React.useState(false)
    const [openEdit, setEditOpen] = React.useState(false)
    const [editBlack, setEditBlack] = React.useState({img_event: []})
    const [dialogData, setDialogData] = React.useState({img_event: []})

  const delImgEvent = (id) => {
    const con = window.confirm("คุณต้องการลบรูปนี้จริงหรือไม่? หากคุณกดตกลงรูปภาพนี้จะโดนลบในทันที")
    if (!con) {
      return false
    }
    let perPath = "/user_api"
    if (getUser().level > 1) {
      perPath = "/admin"
    }
    axios.post(CONFIG.backend.main_url + perPath+"/del_imgblack/" + id, {}, {
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        window.alert("ลบรูปเรียบร้อย")
        setEditOpen(false)
        set_data()
        // window.location.reload()
      }
    })

  }
  const addImgEvent = (e, id) => {
    const con = window.confirm("คุณต้องเพิ่มรูปนี้จริงหรือไม่? หากคุณกดตกลงรูปภาพนี้จะโดนเพิ่มในทันที")
    
    if (!con) {
      return false
    }
    const fd = new FormData()
    fd.append("img", e.target.files[0])
    let perPath = "/user_api"
    if (getUser().level > 1) {
      perPath = "/admin"
    }
    axios.post(CONFIG.backend.main_url + perPath+"/add_imgblack/" + id, fd, {
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        window.alert("เพิ่มรูปเรียบร้อยแล้ว")
        setEditOpen(false)
        set_data()
        // window.location.reload()
      }
    })

  }
  const set_data = () => {
    axios.get(CONFIG.backend.main_url + "/user_api/reported", {
      headers: {
          "auth-token": getToken()
        }
    }).then((res) => {
      setReported(res.data)
      let myList = []
      res.data.map((val, index) => {
        let tmp = []
        tmp.push(index+1)
        tmp.push(val.f_name)
        tmp.push(val.l_name)
        tmp.push(val.detail)
        tmp.push(<Button color="secondary" variant="contained" onClick={() => {
          const con = window.confirm("คุณต้องการลบข้อมูลนี้จริงหรือไม่?");
          if (!con) {
            return false
          }
          let perPath = "/user_api"
          if (getUser().level > 1) {
            perPath = "/admin"
          }
          axios.post(CONFIG.backend.main_url + perPath+"/del_black/" + val.id, {}, {
            headers: {
              "auth-token": getToken()
            }
          }).then((res) => {
            if (res.data == "success") {
              window.alert("ลบข้อมูลเรียบร้อย")
              set_data()
              // window.location.reload()
            }
          })
        }}>ลบ</Button>)
        tmp.push(<Button style={{backgroundColor: "orange", color: "white"}} variant="contained" onClick={() => {
          setEditBlack(val)
          setEditOpen(true)
        }}>แก้ไข</Button>)
        tmp.push(<Button color="primary" variant="contained" onClick={() => {
          setDialogData(val)
          setDialogOpen(true)
        }}>รายละเอียด</Button>)
        myList.push(tmp)
      })
      setReportedList(myList)
      })

    }
  React.useEffect(() => {
    checkToken()
    set_data()
    },[])
    
  function send_edit(id){
    const f_name = document.getElementById("edit_fName").value
    const l_name = document.getElementById("edit_lName").value
    const id_card = document.getElementById("edit_idCard").value
    const detail = document.getElementById("edit_detail").value
    const place = document.getElementById("edit_place").value
    const date = document.getElementById("edit_date").value
    axios.post(CONFIG.backend.main_url + "/user_api/edit_blacklist/" + id, {
      f_name: f_name,
      l_name: l_name,
      id_card: id_card,
      detail: detail,
      place: place,
      date: date
    }, {
        headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        window.alert("แก้ไขข้อมูลเรียบร้อยแล้ว")
        set_data()
        // window.location.reload()
      }
    }).catch((err) => {
      console.log(err)
    })

  }
  function send_editAdmin(id){
    const f_name = document.getElementById("edit_fName").value
    const l_name = document.getElementById("edit_lName").value
    const id_card = document.getElementById("edit_idCard").value
    const detail = document.getElementById("edit_detail").value
    const place = document.getElementById("edit_place").value
    const date = document.getElementById("edit_date").value
    axios.post(CONFIG.backend.main_url + "/admin/edit_blacklist/" + id, {
      f_name: f_name,
      l_name: l_name,
      id_card: id_card,
      detail: detail,
      place: place,
      date: date
    }, {
        headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        window.alert("แก้ไขข้อมูลเรียบร้อยแล้ว")
        set_data()
        // window.location.reload()
      }
    }).catch((err) => {
      console.log(err)
    })

  }
  const getSearch = (text) => {
    // console.log(text)
    // console.log(reported)
    let results = []
    for (let i=0 ; i < reported.length ; i++)
    {
      let myReg = new RegExp(`${text}`)
        if (reported[i].f_name.search(myReg) !== -1) {
            results.push(reported[i]);
        } else if (reported[i].l_name.search(myReg) !== -1) {
            results.push(reported[i]);
        } else if (reported[i].detail.search(myReg) !== -1) {
            results.push(reported[i]);
        } else if (reported[i].id_card.search(myReg) !== -1) {
            results.push(reported[i]);
        } else if (reported[i].date.search(myReg) !== -1) {
            results.push(reported[i]);
        } else if (reported[i].place.search(myReg) !== -1) {
            results.push(reported[i]);
        }
    }
    let myList = []
    results.map((val, index) => {
      
        let tmp = []
        tmp.push(index+1)
        tmp.push(val.f_name)
        tmp.push(val.l_name)
        tmp.push(val.detail)
        tmp.push(<Button color="secondary" variant="contained" onClick={() => {
          const con = window.confirm("คุณต้องการลบข้อมูลนี้จริงหรือไม่?");
          if (!con) {
            return false
          }
          let perPath = "/user_api"
          if (getUser().level > 1) {
            perPath = "/admin"
          }
          axios.post(CONFIG.backend.main_url + perPath+"/del_black/" + val.id, {}, {
            headers: {
              "auth-token": getToken()
            }
          }).then((res) => {
            if (res.data == "success") {
              window.alert("ลบข้อมูลเรียบร้อย")
              set_data()
              // window.location.reload()
            }
          })
        }}>ลบ</Button>)
        tmp.push(<Button style={{backgroundColor: "orange", color: "white"}} variant="contained" onClick={() => {
          setEditBlack(val)
          setEditOpen(true)
        }}>แก้ไข</Button>)
        tmp.push(<Button color="primary" variant="contained" onClick={() => {
          setDialogData(val)
          setDialogOpen(true)
        }}>รายละเอียด</Button>)
        myList.push(tmp)
      })
      setReportedList(myList)
      
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>รปภ.รหัสแดง</h4>
            <p className={classes.cardCategoryWhite}>
            ประวัติการรายงานของคุณ
            </p>
            <div style={{display: "flex"}}>
            <InputBase
            style={{backgroundColor: "white", marginLeft: "auto" , borderRadius: 5}}
            fontSize="20"
                // defaultValue={search}
                onChange={(e) => {
                getSearch(e.target.value)
                
            }}
            
        placeholder="ค้นหา"
      /></div>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["ลำดับที", "ชื่อ", "นามสกุล", "รายละเอียดเหตุการณ์", "ลบ","แก้ไข", "ดูรายละเอียด"]}
              tableData={listReported}
              // tableData={[
              //   [1, "สมชาย", "น้ำดี", "ขโมยของในหน่วยงาน", <button>ลบ</button>, <button>ดูรายละเอียด</button>],
              // ]}
            />
          </CardBody>
        </Card>
      </GridItem>
      <Dialog open={openDialog} onClose={() => {
        setDialogOpen(false)
      }}>
        <DialogTitle>รายละเอียด รปภ รหัสแดง</DialogTitle>
        <DialogContent dividers>
          <img src={`${CONFIG.backend.main_url}/profile_blacklist/${dialogData.img_profile}`} width="100px" height="120px"></img><br />
          <span><b>ชื่อ:</b> { dialogData.f_name }</span><br />
          <span><b>นามสกุล:</b> {dialogData.l_name}</span><br />
          <span><b>เลขบัตรประชาชน:</b> {dialogData.id_card}</span><br />
          <span><b>สถานที่:</b></span> {dialogData.place }<br />
          <span><b>วันที่:</b></span> {String(dialogData.date).split("T")[0]}<br />
          <span><b>รายละเอียด:</b></span> {dialogData.detail}<br />
          <div>
            <span><b>รูปเหตุการณ์: </b>{dialogData.img_event.length ===0? "ไม่มี" : null}</span>
          <br />
            {
              dialogData.img_event.map((val) => {
                return <img width="200px" src={`${CONFIG.backend.main_url}/event_blacklist/${val.img}`} onClick={(e) => {
                  window.open(e.target.src)

                }} />
              })
            }
          </div>
        </DialogContent>

      </Dialog>
      <Dialog open={openEdit} onClose={() => {
        setEditOpen(false)
      }}>
        <DialogTitle>รายละเอียด รปภ รหัสแดง</DialogTitle>
        <DialogContent dividers>
          <img src={`${CONFIG.backend.main_url}/profile_blacklist/${editBlack.img_profile}`} width="100px" id="edit_imgpreview" height="120px" onClick={() => {
            document.getElementById("img_ch").click()
          }}></img><br />
          <input id="img_ch" type="file" onChange={(e) => {
            let fd = new FormData()
            fd.append("img_profile", e.target.files[0])
            let perPath = "/user_api"
            if (getUser().level > 1) {
              perPath = "/admin"
            }
            axios.post(`${CONFIG.backend.main_url}${perPath}/ch_imgblack/${editBlack.id}`, fd, {
              headers: {
                "auth-token": getToken()
              }
            }).then((res)=> {
              if (res.data.success) {
                window.alert("เปลี่ยนรูปเรียบร้อยแล้ว")
                document.getElementById("edit_imgpreview").src = res.data.success.img_url
              }
            })
          }} hidden />
          <TextField defaultValue={editBlack.f_name} id="edit_fName" label="ชื่อ" fullWidth />
          <TextField defaultValue={editBlack.l_name} id="edit_lName" label="นามสกุล" fullWidth />
          <TextField defaultValue={editBlack.id_card} id="edit_idCard" label="เลขบัตรประชาชน" fullWidth />
          <TextField defaultValue={editBlack.place} id="edit_place" label="สถานที่" fullWidth />
                  <TextField
                      defaultValue={String(editBlack.date).split("T")[0]}
                      id="edit_date"
                      label="วันที่"
                      type="date"
                      // defaultValue="2017-05-24"
                      InputLabelProps={{
                          shrink: true,
                      }}
                  />
          <TextField defaultValue={editBlack.detail} id="edit_detail" label="รายละเอียด" rows={4} multiline fullWidth />
          <div>

            {/* {
              editBlack.img_event.map((val) => {
                return <img width="200px" src={`${CONFIG.backend.main_url}/event_blacklist/${val.img}`} onClick={(e) => {
                  window.open(e.target.src)

                }} />
              })
            } */}
                <GridList  >
                  {
                    editBlack.img_event.map((val, index) => (
                      <GridListTile key={"img_" + index} id={"event_"+index}>
                        <img src={`${CONFIG.backend.main_url}/event_blacklist/${val.img}`} />

                        <GridListTileBar 
                          actionIcon={

                            <IconButton onClick={() => {
                              delImgEvent(val.id)
                          }}><DelIcon style={{color: "white"}}></DelIcon></IconButton>
                          }
                        />
                      </GridListTile>
                    ))}
                  <IconButton onClick={()=>{document.getElementById("imgEventFile").click()}}><AddIcon /></IconButton></GridList>
                  <input type="file" id="imgEventFile" onChange={(e)=>{addImgEvent(e, editBlack.id)}} hidden />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            if (getUser().level > 1) {
              send_editAdmin(editBlack.id)
            }else{
              send_edit(editBlack.id)
            }
          }} color="primary">แก้ไขข้อมูล</Button>

        </DialogActions>

      </Dialog>

    </GridContainer>
  );
}
