import React from "react"
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Grid from '@material-ui/core/Grid';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CONFIG from "../../config.json"
import axios from "axios"
import { getToken } from "Utils/Common";
import { getUser } from "Utils/Common";
import Button from "@material-ui/core/Button"
import { TextField } from "@material-ui/core"
import { removeUserSession } from "Utils/Common"
// import Rating from '@material-ui/lab/Rating';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { CameraAltSharp as CamIcon } from "@material-ui/icons"


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);
export default function CompanyDetail(props) {
    // console.log(props)
    // {props.match.params.ref_id}
  const send_comment = (post_id) => {
    const comment = document.getElementById("msg_commentcom").value
    const fd = new FormData()
    fd.append("comment", comment);
    if (img_comment.length > 0) {
      fd.append("img", img_comment[0])

    }
    axios.post(CONFIG.backend.main_url + "/user_api/out_guard/"+post_id+"/comment", fd,{
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        // alert("แสดงความคิดเห็นแล้ว")
        document.getElementById("img_comment").value = ""
        setImg_comment([])
        document.getElementById("msg_commentcom").value = ""
        // window.location.reload()
        set_posts()
        
      }
    }).catch(async (err) => {
      if (err.response.data == "error token") {
        await removeUserSession()
        window.location.reload()
      }
    })

  }
  const convertTime = (myTime) => {
    let m = new Date(myTime)
    let dateString = m.getUTCFullYear() +"/"+ (m.getUTCMonth()+1) +"/"+ m.getUTCDate() + " " + m.getUTCHours() + ":" + m.getUTCMinutes() ;
    return dateString

  }
  const del_comment = (post_id, comment_id) => {
    let url_api = CONFIG.backend.main_url + "/user_api/out_guard/"+post_id+"/comment/"+comment_id+"/del" 
    if (getUser().level > 1) {
      url_api = CONFIG.backend.main_url + "/admin/out_guard/"+post_id+"/comment/"+comment_id+"/del" 
    }
    const con = window.confirm("คุณต้องการลบโพสนี้จริงหรือไม่?")
    if (!con) {
      return false
    }
    axios.post(url_api , {
    },{
      headers: {
        "auth-token": getToken()
      }
    }).then((res) => {
      if (res.data == "success") {
        // alert("ลบความคิดเห็นแล้ว")
        alert("อัพเดตการโหวตแล้ว")
        // window.location.reload()
        set_posts()
        
      }
    }).catch(async (err) => {
      if (err.response.data == "error token") {
        await removeUserSession()
        window.location.reload()
      }
    })
  }
    const [infocom, setInfo] = React.useState({comments: [], imgs: []})
    const [img_comment, setImg_comment] = React.useState({})
    const set_posts = () => {
        const ref_id = props.match.params.ref_id
        axios.get(`${CONFIG.backend.main_url}/user_api/out_guard/${ref_id}`, {
            headers: {
                "auth-token": getToken()
            }
        }).then((result) => {
            setInfo(result.data)

        }).catch(err => {
            console.log(err)
            window.location.href = "/"

        })

    }
    React.useEffect(() => {
        set_posts()

    }, [])
    React.useEffect(() => {
        console.log(infocom)
    }, [infocom])
    const classes = useStyles();

    return <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>รายละเอียด บริษัทดีเด่น</h4>
            {/* <p className={classes.cardCategoryWhite}>
              ตารางบริษัทดีเด่น
            </p> */}
          </CardHeader>
          <CardBody>
            <Grid container spacing={3}>
            <Grid item xs={6}>
                <div>
                                <img src={`${CONFIG.backend.main_url}/profile_out_guard/${infocom.img_profile}`} width="100px"  />
                                <h5>ชื่อ: {infocom.f_name}
                                <br />
                                นามสกุล: {infocom.l_name}
                                <br />
                                หัวข้อที่ได้รับรางวัล: {infocom.title_reward }<br />
                                ผู้ให้ข้อมูล: {infocom.from }<br />
                                รายละเอียด: {infocom.detail}<br />
                                วันที่ได้รับรางวัล: {convertTime(infocom.timestamp)}</h5>
                </div>

            </Grid>

            <Grid item xs={6}>
                <h5>รูปภาพ</h5>
                            {
                                infocom.imgs.length == 0 ? "ไม่มีรูป" : 
                                    infocom.imgs.map((val) => {
                                        return (<img src={`${CONFIG.backend.main_url}/profile_out_guard/${val.img}`} width="120px" />)
                                    })
                }

            </Grid>

              </Grid>
              

          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
}