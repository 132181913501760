/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
// import { Navbar } from "react-bootstrap";
// import { Button } from "@material-ui/core";
// import TreeIcon from '@material-ui/icons/AccountTree';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import StarBorder from '@material-ui/icons/StarBorder';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { removeUserSession } from "../../Utils/Common"

const useStyles = makeStyles(styles);


export default function Sidebar(props) {
  const [openG, setG] = React.useState(false)
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, routes, goodG } = props;
  var links = (
    <List className={classes.list}>
          <NavLink
        to={"#"}
        className={classes.item}
        activeClassName="active"
        key={"goodG"}
        onClick={() => {
          setG(!openG)
        }}
          >
          <ListItem button className={classes.itemLink} style={{display: "flex"}}>
              <Icon
                className={classes.itemIcon}
              >
                {"content_paste"}
              </Icon>
          <ListItemText primary={"ผลรางวัลดีเด่นประจำปี"}
                className={classes.itemText}
                disableTypography={true}
                
              />
              {openG ? <ExpandLess style={{marginLeft: "auto"}}/> : <ExpandMore style={{marginLeft: "auto"}} />}
          </ListItem>
        <Collapse in={openG} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavLink
              to={"#"}
              className={classes.item}
              activeClassName="active"
            >
                <ListItem button style={{ marginLeft: 4 }}
                  onClick={() => { window.location.href = "/m/out_com" }}
                >
                  <ListItemIcon />
                    {/* <StarBorder /> */}
                {/* <ListItemText primary="บริษัท รปภ. ดีเด่น" /> */}
                <small>บริษัท รปภ. ดีเด่น</small>
                </ListItem>
            </NavLink>


            <NavLink
              to={"#"}
              className={classes.item}
              activeClassName="active"
            >
                <ListItem button style={{ marginLeft: 4 }} onClick={() => {
                  window.location.href = "/m/out_guard"
            }}>
              <ListItemIcon>
                {/* <StarBorder /> */}
              </ListItemIcon>
              {/* <ListItemText primary="เจ้าหน้าที่ รปภ. ดีเด่น" /> */}
                <small>เจ้าหน้าที่ รปภ. ดีเด่น</small>
            </ListItem>
            </NavLink>


          </List>

        </Collapse>

          </NavLink>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path)
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
        });
        return (
          <NavLink
            to={prop.layout + prop.path}
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                />
              )}
              <ListItemText
                primary={props.rtlActive ? prop.rtlName : prop.name}
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: props.rtlActive
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}

          <NavLink
            to={"#"}
            activeClassName="active"
          >
        <ListItem button className={classes.itemLink}
            style={{ color: "white", display: "flex" }}
          onClick={() => {
                        removeUserSession()
                        window.location.href = "/login"

            }}
        >
              <ExitToAppIcon />
              &nbsp;
              <ListItemText
                primary={"ออกจากระบบ"}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>

          
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="/"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
        // target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks setDialog={props.openProfile} />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{backgroundColor: "#9d0b0e"}}
              // style={{backgroundColor: "red"}}
              // style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              // style={{ backgroundImage: "url(" + image + ")" }}
              // style={{backgroundColor: "red"}}
              style={{backgroundColor: "#9d0b0e"}}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
